import React from 'react'
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill'


const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "imageBlot" // #5 Optional if using custom formats
];

export default function ReactQuillComponent(props) {

    return (

        <ReactQuill
            placeholder='Description'
            theme="snow"
            id='input-quill'
            value={props.content}
            modules={{ toolbar: ['bold', 'italic', 'underline', 'link', 'list'] }}
            onChange={(val) => {
                props.setContent(val);
                if (props.lengthFunc) {
                    props.lengthFunc()
                }
            }}

            formats={formats}
        />

    )
}
