export default function CountryFilter({
    heading_label,
    heading_icon,
    heading_link,
    id,
    name,
    disbled,
    canUpdate,
    country,
    countriesList,
    autoComplete,
    onChange,
    ...props }) {

    return (
        <div>
            {heading_label && (
                <div className="block">
                    <label
                        htmlFor=""
                        className="flex justify-start items-center space-x-2 text-[14px] font-medium mb-2"
                    >
                        <span>{heading_label} {heading_link && <a href={heading_link.href} target="_blank">{heading_link.title}</a>}</span>
                        <span className="text-[20px] text-[#7F8783]">
                            {heading_icon}
                        </span>
                    </label>
                </div>
            )}
            <select
                id="tabs"
                name="tabs"
                autoComplete={autoComplete}
                disabled={!canUpdate}
                className={`${canUpdate ? '' : 'text-gray-400'
                    } w-full border-[#BDC7C2] border-[1px] rounded-[8px] focus:border-green-500 focus:ring-0 text-[14px] h-[40px]`}
                value={country}
                defaultValue={country}
                onChange={onChange}
            >
                {countriesList && countriesList?.map((country) => (
                    <option value={country}>{country}</option>
                ))}
            </select>
        </div>
    )
}