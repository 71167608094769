import { useQuery } from '@tanstack/react-query';
import { axiosProtected } from '../utils/axios-interceptor';
import { TimeDuration } from '../utils/calculateTime';

export const getData = async ({ queryKey }) => {
  const { selectedFilter, hardwareId } = queryKey[1];
  const { startDate, endDate } = TimeDuration(selectedFilter);
  let url = `${process.env.REACT_APP_IP}/data/allData?`;

if (startDate) {
  url += `startDate=${encodeURIComponent(startDate)}&`;
}

if (endDate) {
  url += `endDate=${encodeURIComponent(endDate)}&`;
}

if (hardwareId) {
  url += `hardwareId=${encodeURIComponent(hardwareId)}&`;
}


url = url.endsWith('&') ? url.slice(0, -1) : url;

  const { data } = await axiosProtected.get(url);
  return data;
};

const getDataById = async ({queryKey}) => {
  const id = queryKey[1]
  const { data } = await axiosProtected.get(`${process.env.REACT_APP_IP}/data/getDataById`,{params:id})
  return data
}

export const useGetDataById = (query) => {
  return useQuery(['DataById', query], getDataById);
};

export const useGetData = (query) => {
  return useQuery(['filterByDate', query], getData);
};
