import React from 'react';
import { JsonView } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { useGetDataById } from '../../hooks/useData';

const JsonEditor = ({ propertyId }) => {
  const { data, isLoading, isError } = useGetDataById({ id: propertyId });
  const preparedData = data && data.length > 0 ? data[0].data : {};
  if (isLoading) {
    return <div className='flex w-full justify-center'>Loading...</div>;
  }

  if (isError) {
    return <div>Error loading data</div>;
  }

  return (
    <JsonView
      data={preparedData}
      onEdit={() => { }}
    />
  );
};

export { JsonEditor };
