import { XCircleIcon } from '@heroicons/react/24/solid';
import React from 'react';

export const ErrorAlert = ({ errorMessages }) => (
    <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
            <div>
                <div className="text-sm text-red-700">
                    {errorMessages && errorMessages.length > 0 && (
                        <ul className="pl-5 space-y-1">
                            <p />
                            {Array.isArray(errorMessages)
                                ? errorMessages?.map((message) =>
                                    <div className='flex gap-x-1 items-center'>
                                        <XCircleIcon className="h-5 w-5 text-red-400 " aria-hidden="true" />
                                        <li key={message}>{message}</li>
                                    </div>
                                )
                                : <div className='flex gap-x-1 items-center'>
                                    <XCircleIcon className="h-5 w-5 text-red-400 " aria-hidden="true" />
                                    <li key={errorMessages}>{errorMessages}</li>
                                </div>}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    </div>
);

