import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { axiosProtected } from "../utils/axios-interceptor"


const fetchHardwares = async () => {
    const { data } = await axiosProtected.get(`${process.env.REACT_APP_IP}/hardware/getHardwares`)
    return data
}

const addHardware = async (hardware) => {
    const { data } = await axiosProtected.post(`${process.env.REACT_APP_IP}/hardware/addHardware`, hardware)
    return data
}

const editHardware = async (hardware) => {
    const { data } = await axiosProtected.patch(`${process.env.REACT_APP_IP}/hardware/editHardware`, hardware)
    return data
}

const deleteHardware = async (hardwareId) => {
    const { data } = await axiosProtected.delete(`${process.env.REACT_APP_IP}/hardware/deleteHardware`, { data: { hardwareId }})
    return data
}

export const useFetchHardwares = () => {
    return useQuery(['hardwares'], fetchHardwares)
}

export const useAddHardware = () => {
    const queryClient = useQueryClient()
    return useMutation(addHardware, {
        onSuccess: () => {
            queryClient.invalidateQueries('hardwares')
        }
    })
}

export const useEditHardware = () => {
    const queryClient = useQueryClient()
    return useMutation(editHardware, {
        onSuccess: () => {
            queryClient.invalidateQueries('hardwares')
        }
    })
}

export const useDeleteHardware = () => {
    const queryClient = useQueryClient()
    return useMutation(deleteHardware, {
        onSuccess: () => {
            queryClient.invalidateQueries('hardwares')
        }
    })
}