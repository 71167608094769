import React, { useState } from 'react';
import DashboardLayout from '../components/DashboardLayout';

import BillingHistory from '../components/BillingHistory/BillingHistory';
import EditProfile from '../components/EditProfile/EditProfile';
import { Tabs as AccountTabs } from '../components/Tabs/Tabs';
import Settings from './settings';

const MyAccount = () => {

    const sectionTabs = [
        {
            title: 'Account',
            identifier: 'ACCOUNT',
        },
        {
            title: 'Billing History',
            identifier: 'BILLINGHISTORY',
        },
        {
            title: 'Settings',
            identifier: 'SETTINGS',
        },
    ];

    const [currentTab, setCurrentTab] = useState(sectionTabs[0]);

    const handleTabsClick = (identifier) => {
      // if (identifier === 'SUBSCRIPTIONS') {
      //     setCurrentTab(sectionTabs[0]);
      // } else 
      if (identifier === 'ACCOUNT') {
          setCurrentTab(sectionTabs[0]);
      } else if (identifier === 'BILLINGHISTORY') {
          setCurrentTab(sectionTabs[1]);
      } else {
          setCurrentTab(sectionTabs[2]);
      }
  }

  const accountTabOptions = {
      active: currentTab.identifier,
      fullWidth: true,
      tabs: [
          // { name: 'Subscriptions', identifier: 'SUBSCRIPTIONS' },
          { name: 'Account', identifier: 'ACCOUNT' },
          // { name: 'Payment Info', identifier: 'PAYMENTINFO' },
          { name: 'Billing History', identifier: 'BILLINGHISTORY' },
          { name: 'Settings', identifier: 'SETTINGS' },
      ],
      handleClick: handleTabsClick
  };

  return (
    <DashboardLayout>
      <div className="p-5 xl:w-4/5">
        <h1 className='text-2xl font-bold p-3'>Hello Tobias</h1>
        <AccountTabs {...accountTabOptions} />
      </div>
      <div className="flex flex-col w-full xl:w-4/5 px-5">{
        currentTab.identifier === "ACCOUNT" ? <EditProfile /> : currentTab.identifier === "BILLINGHISTORY" ? <BillingHistory /> : <Settings />
      }</div>
    </DashboardLayout>
  )
}

export default MyAccount