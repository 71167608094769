import { useDispatch, useSelector } from "react-redux"
import { setHardware } from "../../features/jobDetailsSlice"

export default function ChooseHardware({ hardware }) {
    const dispatch = useDispatch()
    const hardwares = useSelector(state => state.jobDetails.hardwares)
    const onClickHardware = (hardware) => {
        dispatch(setHardware(hardware._id))
    }
    let selected = hardwares.includes(hardware._id)

    return (
        <div className={`border border-gray-300 rounded-md p-4 shadow-sm ${selected ? 'border-blue-500 bg-blue-50' : 'bg-white'}`}>
            <div className='flex justify-between'>
                <h3 className='font-medium text-2xl text-gray-900'>{hardware.name}</h3>
            </div>

            <hr className='mt-1 border-gray-300' />
            <br />

            <span className='flex items-baseline'>
                <p className='text-gray-500'>hwid:&nbsp;</p>
                <h6 className="text-blue-600 font-bold">{hardware.hwid}</h6>
            </span>

            <span className='flex items-baseline'>
                <p className='text-gray-500'>Description:&nbsp;</p>
                <p dangerouslySetInnerHTML={{ __html: hardware.description }} className='text-sm'></p>
            </span>

            <button
                className={`mt-3 p-2 text-white rounded-md text-sm ${selected ? 'bg-red-500 hover:bg-red-600' : 'hover:bg-blue-600 bg-blue-500'}`}
                onClick={() => {onClickHardware(hardware)}}
            >
                {selected ? 'Unselect Hardware' : 'Select Hardware'}
            </button>
        </div>
    )
}
