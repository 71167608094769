import Gear from '../../assets/Gear.svg'
import { useEndJob } from '../../hooks/useJobs'
import { useDispatch, useSelector } from 'react-redux'
import { setJobInProgress } from '../../features/jobInProgressSlice'
import { setStep } from '../../features/jobDetailsSlice'

export default function JobInProgress() {
    const dispatch = useDispatch()
    const { mutateAsync: endJob } = useEndJob()
    const jobInProgress = useSelector(state => state.jobInProgress)
    const handleClick = async () => {
        const jobEndTime = new Date()
        const data = await endJob({ id: jobInProgress.jobInProgressDoc._id, startTime: jobInProgress.jobInProgressDoc.startTime, endTime: jobEndTime})
        dispatch(setJobInProgress(data))
        dispatch(setStep(3))
    }
    
    return (
        <div className="block mt-20 text-center">
            <img src={Gear} alt="job in progress" className="mx-auto" />
            <p className="text-8xl font-light">Your job is in progress</p>
            <button
                className="bg-red-600 px-20 py-2 text-white font-medium text-lg rounded-md mt-10 hover:shadow-[0_0_20px_rgba(220,38,38,1)] transition-all duration-300"
                onClick={handleClick}
            >Complete Job
            </button>
        </div>
    )
}
