import React from 'react'

const BillingHistory = () => {

    return (
        <div><div className='flex justify-between items-center px-3'>
            <h1 className='text-base font-bold'>Billing history</h1>
            {/* <div>
              <DateRange
                  onChange={item => setDateRange([item.selection])}
                  onClick={() => setIsOpen(!isOpen)}
                  ranges={dateRange}
                  isOpen={isOpen}
              />
          </div> */}
        </div>
        
        <hr className='my-5 bg-gray-100 text-gray-100' />

        <div className="py-10 text-center">
            <h1 className="text-2xl font-bold">We could not find any billing history</h1>
            {/* <h1 className="text-base font-normal">Please enter your search again</h1> */}
        </div>
        </div>
    )
}

export default BillingHistory