import { useNavigate } from 'react-router-dom'
import DashboardLayout from '../components/DashboardLayout';
import {
  MapPinIcon,
  BeakerIcon,
  BoltIcon,
  WrenchScrewdriverIcon
} from '@heroicons/react/24/outline'
import { useUser } from '../hooks/useAuth';

export default function Dashboard() {
  const nav = useNavigate();
  const {data: userData} = useUser()

  const userActions = [
    {
        icon: BeakerIcon,
        name: 'Chemicals',
        href: '/chemicals',
        iconForeground: 'text-teal-700',
        iconBackground: 'bg-teal-50',
        description: 'This is the chemical data'
    },
    {
        icon: MapPinIcon,
        name: 'Map',
        href: '/mapview',
        iconForeground: 'text-purple-700',
        iconBackground: 'bg-purple-50',
        description: 'This is the map'
    },
    {
        icon: BoltIcon,
        name: 'Start Job',
        href: '/start',
        iconForeground: 'text-sky-700',
        iconBackground: 'bg-sky-50',
        description: 'Click here to start a job'
    },
    {
      icon: WrenchScrewdriverIcon,
      name: 'Hardware',
      href: '/hardware',
      iconForeground: 'text-red-700',
      iconBackground: 'bg-red-50',
      description: 'Click here to add a hardware'
  }
];

const technicianActions = [
  {
      icon: MapPinIcon,
      name: 'Map',
      href: '/mapview',
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50',
      description: 'This is the map'
  },
  {
      icon: BoltIcon,
      name: 'Start Job',
      href: '/start',
      iconForeground: 'text-sky-700',
      iconBackground: 'bg-sky-50',
      description: 'Click here to start a job'
  },
  {
    icon: WrenchScrewdriverIcon,
    name: 'Search',
    href: '/search',
    iconForeground: 'text-red-700',
    iconBackground: 'bg-red-50',
    description: 'Click here to search all jobs'
}
];

const actions = userData?.user?.role === "user" ? userActions : technicianActions

  return (
    <>
      <DashboardLayout>
        <section className='m-5'>
          <div className='grid md:grid-cols-3 gap-4 sm:grid-cols-2'>
            {actions.map(action => (
              <div key={action.name} 
                className='border rounded-lg shadow-md cursor-pointer hover:scale-105 transition-all hover:shadow-lg group bg-white'  
                onClick={() => nav(action.href)}
                >
                <div className='m-5'>
                  <div className='flex justify-between items-center'>
                    <span className='w-6'>
                      <action.icon className={`${action.iconForeground} ${action.iconBackground} p-3 rounded-lg`} />
                    </span>
                    <span
                      className="text-gray-300 group-hover:text-gray-400 transition-all"
                      aria-hidden="true"
                    >
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                      </svg>
                    </span>
                  </div>
                  <div className='mt-5'>
                    <h3 className='font-medium text-xl'>{action.name}</h3>
                    <p className='mt-2 text-gray-500 text-sm'>{action.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </DashboardLayout>
    </>
  );
}