import React from 'react'

export function SecondaryButton({ label, icon, onClick }) {
    return (
        <button className='flex gap-x-1 border-2 px-3 md:px-4 py-2 rounded-lg text-[12px] leading-4 items-center hover:border-gray-300' onClick={onClick}>
            {icon}
            {label}
        </button>
    )
}
