import { useMutation, useQuery } from '@tanstack/react-query'
import { TimeDuration } from '../utils/calculateTime'
import { axiosProtected } from '../utils/axios-interceptor'

const createJob = async (job) => {
    const { data } = await axiosProtected.post(`${process.env.REACT_APP_IP}/jobs/createJob`, job)
    return data
}

const getAllUniqueJobs = async ({queryKey}) => {
    const query = queryKey[1].selectedFilter
    const user = queryKey[1]?.user
    const technician = queryKey[1]?.technician
    const { startDate, endDate } = TimeDuration(query)
    if (user !== undefined && technician === null) {
        const { data } = await axiosProtected.get(`${process.env.REACT_APP_IP}/jobs/activeJobs/${startDate}/${endDate}/${user}/user`)
        return data
    } else if (technician !== null) {
        const { data } = await axiosProtected.get(`${process.env.REACT_APP_IP}/jobs/activeJobs/${startDate}/${endDate}/${technician}/technician`)
        return data
    }
}

const endJob = async (id) => {
    const { data } = await axiosProtected.patch(`${process.env.REACT_APP_IP}/jobs/endJob`, id)
    return data
}

const getJobsOnProperty = async ({ queryKey }) => {
    const { propertyId, user } = queryKey[1]
    const { data } = await axiosProtected.get(`${process.env.REACT_APP_IP}/jobs/jobsOnProperty`, { params: { propertyId, user}})
    return data
}

const getJobById = async ({ queryKey }) => {
    const jobId = queryKey[1]
    const { data } = await axiosProtected.get(`${process.env.REACT_APP_IP}/jobs/jobById`, { params: { jobId } })
    return data
}

const isJobActive = async ({ queryKey }) => {
    const { address, user } = queryKey[1]
    const { data } = await axiosProtected.get(`${process.env.REACT_APP_IP}/jobs/isJobActive`, { params: { address, user }})
    return data
}

const searchJobs = async ({ queryKey }) => {
    const { user, search, page, technician ,role, active} = queryKey[1]

    const { data } = await axiosProtected.get(
        `${process.env.REACT_APP_IP}/jobs/searchJobs`,
        {
            params: { user, search, page, technician, role, active }
        })
    return data
}


export const useCreateJob = () => {
    return useMutation(createJob)
}

export const useGetAllUniqueJobs = (query) => {
    return useQuery(['filterByDate', query], getAllUniqueJobs)
}

export const useEndJob = () => {
    return useMutation(endJob)
}

export const useGetJobsOnProperty = (query) => {
    return useQuery(['propertyJobList', query], getJobsOnProperty)
}

export const useGetJobById = (query) => {
    return useQuery(['JobById', query], getJobById)
}

export const useIsJobActive = (query) => {
    return useQuery(['isJobActive', query], isJobActive, {
        enabled: false
    })
}

export const useSearchJobs = (query) => {
    return useQuery(['searchJob', query], searchJobs, {
        enabled: false,
        cacheTime: 0
    })
}