import React from 'react'
import {
    CheckIcon,
    ArrowPathIcon,
    ArrowLeftIcon
} from '@heroicons/react/20/solid'
import { useGetJobsOnProperty } from '../../hooks/useJobs'
import dayjs from 'dayjs'
import { ErrorAlert } from '../Popups/ErrorAlert'
import { useUser } from '../../hooks/useAuth'
import { Loader } from '../Loader/Loader'

export default function JobsTimeline({ propertyId, setStep, setJobId }) {
    const { data: userData } = useUser()

    const { data, isLoading, isError, error } = useGetJobsOnProperty({ propertyId, user: userData?.user?._id})

    const handleBack = () => {
        setStep(1)
    }

    const handleJobDetails = (jobId) => {
        setJobId(jobId)
        setStep(3)
    }

    if (isLoading) return <Loader />
    if (isError) return <ErrorAlert errorMessages={error.message} />
    return (
        <div className="mt-6 flow-root">
            <div className="px-4 py-3 sm:rounded-lg sm:px-6">
                <div className='mt-[-20px]'>
                    <button onClick={handleBack} type="button" className=" text-blue-700 font-medium rounded-full hover:bg-blue-100 bg-blue-50 text-sm p-2.5 text-center inline-flex items-center">
                        <ArrowLeftIcon className='w-6 h-6' />
                    </button>
                </div>
                <ul className='mt-2'>
                    <li>
                        <h2 id="timeline-title" className="text-lg font-medium text-gray-900 mt-2">
                            {data[0]?.address.address}
                        </h2>
                    </li>
                    {data.map((job, jobIdx) => (
                        <li key={job.id}>
                            <div className="relative pb-8">
                                {jobIdx !== data.length - 1 ? (
                                    <span
                                        className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                        aria-hidden="true"
                                    />
                                ) : null}
                                <div className="relative flex items-center space-x-3">
                                    <div>
                                        <span
                                            className=
                                            {`mt-2 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white 
                                            ${job.active === false ? 'bg-blue-500' : 'bg-purple-500'}`}
                                        >
                                            {job.active === false ? <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" /> :
                                                <ArrowPathIcon className="h-5 w-5 text-white" aria-hidden="true" />}
                                        </span>
                                    </div>
                                    <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                                        <div>
                                            {job.active === false ? 
                                            <button onClick={() => handleJobDetails(job._id)} className="font-medium text-gray-900 hover:text-gray-500">
                                                job completed at {' '}
                                                
                                                {dayjs(job.endTime).format('DD MMM')}
                                            </button> :
                                                <button onClick={() => handleJobDetails(job._id)} className="font-medium text-gray-900 hover:text-gray-500">
                                                    job started at {' '}
                                                    <span className='text-gray-600'>
                                                        {dayjs(job.startTime).format('DD MMM')}
                                                    </span>
                                                </button>}
                                        </div>
                                        <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
