import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { axiosProtected } from '../utils/axios-interceptor'

const createProperty = async (addressData) => {
    const { data } = await axiosProtected.post(`${process.env.REACT_APP_IP}/property/createproperty`, addressData)
    return data
}

export const useCreateProperty = () => {
    return useMutation(createProperty)
}