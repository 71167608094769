import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    jobInProgressDoc: {}
}

export const jobInProgressDoc = createSlice({
    name: 'jobInProgressDoc',
    initialState,
    reducers: {
        setJobInProgress: (state, action) => {
            state.jobInProgressDoc = action.payload
        }
    }
})

export const { setJobInProgress } = jobInProgressDoc.actions

export default jobInProgressDoc.reducer