import { useDispatch, useSelector } from "react-redux"
import { setChemical } from "../../features/jobDetailsSlice"

export default function ChooseChemical({ chemical }) {
    const dispatch = useDispatch()
    const chemicals = useSelector(state => state.jobDetails.chemicals)
    const onClickChemical = (chem) => {
        dispatch(setChemical(chem._id))
    }
    let selected = chemicals.includes(chemical._id)

    return (
        <div className={`border border-gray-300 rounded-md p-4 shadow-sm ${selected ? 'border-blue-500 bg-blue-50' : 'bg-white'}`}>
            <div className='flex justify-between'>
                <h3 className='font-medium text-2xl text-gray-900'>{chemical.chemicalName}</h3>
            </div>

            <hr className='mt-1 border-gray-300' />
            <br />

            <span className='flex items-baseline'>
                <p className='text-gray-500'>Description:&nbsp;</p>
                <p dangerouslySetInnerHTML={{ __html: chemical.description }} className='text-sm'></p>
            </span>

            <span className='flex items-baseline'>
                <p className='text-gray-500'>Concentration:&nbsp;</p>
                <h6>{chemical.concentration} {chemical.measurement}</h6>
            </span>

            <button
                className={`mt-3 p-2 text-white rounded-md text-sm ${selected ? 'bg-red-500 hover:bg-red-600' : 'hover:bg-blue-600 bg-blue-500'}`}
                onClick={() => onClickChemical(chemical)}
            >
                {selected ? 'Unselect Chemical' : 'Select Chemical'}
            </button>
        </div>
    )
}
