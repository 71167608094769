import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { ChevronUpIcon } from '@heroicons/react/24/outline'

export default function ProfileMenu({ contractorDropDownOptions, username, avatar, screenType, ...props }) {

    const isAdmin = contractorDropDownOptions.length === 3

    return (
        <div className="w-full">
            <Menu as="div" className="relative w-full inline-block text-left">
                <div>
                    <Menu.Button className="inline-flex w-full justify-between items-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-blue-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                        <div className='flex justify-start items-center gap-2 w-full'>
                            {avatar ?
                                <img
                                    className="h-8 w-8 rounded-full"
                                    src={avatar}
                                    alt="avatar"
                                /> :
                                <div className='rounded-full bg-blue-50 border border-blue-200 p-2 min-w-fit'>
                                    <img src="https://res.cloudinary.com/duynyjen3/image/upload/v1683092830/Assets/sidebar/person_vr2994.svg" alt="profile" className='w-8 h-8' />
                                </div>
                            }
                            <div className='font-medium text-[14px] flex-grow-1'><span>{username}</span></div>
                        </div>
                        <ChevronUpIcon
                            className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                            aria-hidden="true"
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className={`absolute right-[-3rem] md:right-[-12.5rem] top-[-7rem] ${isAdmin ? "md:top-[-2.5rem]" : "md:top-0"} z-50 -mt-8 mx-[-10px] ${screenType === "small screen" ? "mt-16 md:mr-10 md:mt-[-20px] mx-[-15px]" : ""} w-52 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                        <div className="px-1 py-1 ">
                            {contractorDropDownOptions.map((val, index) => (
                                <Menu.Item key={val.name}>
                                    {({ active }) => (
                                        <button
                                            className={`
                    ${active ? 'bg-blue-50 text-blue-500 hover:stroke-blue-500 hover:fill-blue-500' : 'text-gray-900  stroke-gray-400'}
                    group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            onClick={val.onClick}
                                        >
                                            <div className='mr-2'>{val.icon}</div>
                                            {val.name}
                                        </button>
                                    )}
                                </Menu.Item>
                            ))}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}
