import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { verifyEmail } from '../hooks/useAuth'

const AcceptInvitation = () => {

    const navigate = useNavigate();

    const { email } = useParams()

    const [isVerified, setIsVerified] = useState(false)

    useEffect(() => {
        if (email) {
            verifyEmail(email)
            setIsVerified(true)
        }
    }, [email])

    const getVerificationStatus = () => {
        if (isVerified) {
            setTimeout(() => {
                navigate("/signin")
            }, 1000 * 2);
            return (
                <>
                    <p className="mb-4">
                        <strong>Invitation Accepted</strong>
                    </p>
                    <p>You will be redirected soon to sign in page.</p>
                </>
            );
        }
        if (!isVerified) {
            return (
                <p className="mb-0">
                    <strong>Invitation could not be accepted. Something went wrong.</strong>
                </p>
            );
        }
        return null;
    };

    return (
        <div className="mt-28 flex justify-center">
            <div className="flex flex-col items-center">
                {getVerificationStatus()}
            </div>
        </div>
    )
}

export default AcceptInvitation