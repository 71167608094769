import ChooseChemical from "./ChooseChemical";
import { ErrorAlert } from "../Popups/ErrorAlert";
import { Loader } from "../Loader/Loader";
import ChooseHardware from "./ChooseHardware";


export function ChooseList({ title, data, isError, isLoading, error }) {

    if (isLoading) return <Loader />
    if (isError) return <ErrorAlert errorMessages={error.message} />
    return (
        <div className="pb-5">
            <h2 className="text-center text-3xl">{title}</h2>
            <br />
            <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-4">
                {title === 'Chemicals' && data.map(chemical => (
                    <div key={chemical._id}>
                        <ChooseChemical chemical={chemical} />
                    </div>
                ))}
            </div>
            <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-4">
                {title === 'Hardwares' && data.map(hardware => (
                    <div key={hardware._id}>
                        <ChooseHardware hardware={hardware} />
                    </div>
                ))}
            </div>
            <br />
        </div>
    )
}