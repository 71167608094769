import React, { useEffect, useState } from 'react';
import { Toaster, toast } from 'sonner';
import DashboardLayout from '../components/DashboardLayout';
import FileUpload from '../components/FileUpload/file-upload';
import { useFetchVersions, usePublishVersion } from '../hooks/useVersions';

const Versions = () => {
  const { mutate } = usePublishVersion();
  const { data: versions } = useFetchVersions();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [name, setName] = useState('');
  const [enabled, setEnabled] = useState(false);

  const disabledButtonStyles = enabled
    ? 'bg-blue-600 hover:bg-blue-700 cursor-pointer'
    : 'bg-gray-400 cursor-not-allowed';

  useEffect(() => {
    setEnabled(name.length > 0 && uploadedFiles.length > 0);
  }, [name, uploadedFiles.length]);

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('file', uploadedFiles[0]);

    mutate(formData, {
      onSuccess: () => {
        setName('');
        setUploadedFiles([]);
        toast.success('Version Created Successfully');
      },
      onError: (error) => {
        toast.error(error.message);
      }
    });
  };


  const MapVersions = () => {
    return versions?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((item, index) => (
      <div
        key={index}
        className="text-md text-sky-700 bg-gray-50 shadow-md border border-gray-300 rounded-md p-2 flex"
      >
        {item.name}
      </div>
    ))
  }

  return (
    <div>
      <Toaster position="top-right" richColors />
      <DashboardLayout>
        <div className="w-full flex flex-wrap justify-start items-center p-5 flex-col h-screen divide-y">
          <h1 className='text-3xl font-medium text-gray-800 pb-3'>Version Updates and Monitoring</h1>
          <div className="flex w-full h-20 justify-start items-center">
            <span className="w-1/3 items-end gap-3 flex flex-wrap pb-4">
              <label className='pb-1 text-md font-medium'>Enter Version Name:</label>
              <div className='py-1 px-2 border-b border-gray-300 focus:border-sky-700'>
                <input
                  type='text'
                  className='w-60 p-2 h-6 text-md bg-white border-none outline-none focus:ring-0 text-gray-800'
                  placeholder='Enter text'
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
            </span>
          </div>
          <div className="w-full gap-5 py-4 flex h-4/5">
            <div className="h-full w-4/5 p-4 justify-around flex flex-col">
              <FileUpload setFileSelect={setUploadedFiles} files={uploadedFiles} />
              <div className="w-full flex justify-center items-end h-full">
                <button
                  className={`px-4 py-2 rounded-md text-white font-semibold ${disabledButtonStyles}`}
                  onClick={handleSubmit}
                  disabled={!enabled}
                >
                  Upload to Server
                </button>
              </div>
            </div>
            <div className="h-full flex flex-col flex-wrap w-1/4 border rounded-xl p-4 divide-y">
              <h1 className="pb-2 text-center text-gray-900 font-semibold text-lg">Available Versions</h1>
              <div className="py-2 flex flex-col gap-2 overflow-y-auto max-h-[93%] w-full">
                <MapVersions />
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </div>
  );
};

export default Versions;
