import React from 'react'
import Card from '../Card/Card.jsx'
import { useUser } from '../../hooks/useAuth.js'

export default function CardList({ data, onEditClick, onDeleteClick, renderType }) {
    const { data: userData } = useUser()
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-x-[25px] gap-y-6'>
            {renderType === 'chemicals' && data?.map(variant =>
                <div key={variant._id}>
                    <Card
                        name={variant.chemicalName}
                        concentration={variant.concentration}
                        description={variant.description}
                        measurement={variant.measurement}
                        onEditClick={() => onEditClick(variant)}
                        onDeleteClick={() => onDeleteClick(variant._id)}
                    />
                </div>
            )}

            {renderType === 'hardwares' && data?.map(variant =>
                <div key={variant._id}>
                    <Card
                        name={variant.name}
                        concentration={variant.hwid}
                        description={variant.description}
                        onEditClick={() => onEditClick({ ...variant, defaultHardware: variant._id === userData?.user?.defaultHardware })}
                        onDeleteClick={() => onDeleteClick(variant._id)}
                        marker={variant._id === userData?.user?.defaultHardware}
                    />
                </div>
            )}
        </div>
    )
}
