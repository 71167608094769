import * as React from 'react';
import { useState } from 'react'
import { useCreateChemical, useDeleteChemical, useFetchChemicals, useUpdateChemical } from '../hooks/useChemicals';
import DashboardLayout from '../components/DashboardLayout';
import CardList from '../components/CardList/CardList.jsx';
import { PrimaryButton } from '../components/Button/PrimaryButton';
import { ErrorAlert } from '../components/Popups/ErrorAlert';
import { PopupBox } from '../components/Popups/PopupBox';
import { DeletePopup } from '../components/Popups/DeletePopup';
import { useEffect } from 'react';

export default function Dashboard() {
  const { mutate: deleteChemical } = useDeleteChemical()
  const { mutate: addChemical } = useCreateChemical()
  const { mutate: updateChemical } = useUpdateChemical()
  const { isLoading, data, error, isError } = useFetchChemicals()
  // chemical states
  const [errors, setErrors] = useState([])
  const [chemicalModal, setChemicalModal] = useState(false)
  const [chemicalName, setchemicalName] = useState('')
  const [chemicalContent, setchemicalContent] = useState('')
  const [chemicalConcentration, setChemicalConcentration] = useState()
  const [chemicalId, setChemicalId] = useState(null)
  const dropdownOptions = [
    { name: 'Grams per gallon' },
    { name: 'Ounces per gallon' },
    { name: 'Mililiter per gallon' },
  ]
  const [measurementUnit, setMeasurementUnit] = useState(dropdownOptions[0].name)
  //delete state
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  
  useEffect(() => {
    setErrors([])
}, [chemicalName, chemicalContent, chemicalConcentration])

  function handleSubmit() {
      if (!chemicalName) {
          setErrors(['Chemical name is not defined.'])
          return
      } else if (!chemicalContent) {
          setErrors(['Chemical description is not defined.'])
          return
      } else if (!chemicalConcentration) {
          setErrors(['Chemical concentration is not defined.'])
          return
      } else if (chemicalConcentration <= 0) {
        setErrors(['Chemical concentration must be greater than 0'])
      } else {
          if (chemicalId) {
              updateChemical({
                  sprayId: chemicalId,
                  chemical: chemicalName,
                  description: chemicalContent,
                  concentration: chemicalConcentration,
                  selectedMeasurement: measurementUnit
              })
          } else {
              addChemical({ chemical: chemicalName, description: chemicalContent, concentration: chemicalConcentration, selectedMeasurement: measurementUnit })
          }
          setChemicalModal(false)
          setchemicalName('')
          setchemicalContent('')
          setChemicalConcentration('')
          setMeasurementUnit(dropdownOptions[0].name)
      }
  }

  const handleEditClick = (card) => {
    setChemicalId(card._id)
    setchemicalName(card.chemicalName)
    setChemicalConcentration(card.concentration)
    setchemicalContent(card.description)
    setMeasurementUnit(card.measurement)
    setChemicalModal(true)
  };

  const handleCreateClick = () => {
    setChemicalId(null)
    setchemicalName('')
    setchemicalContent('')
    setChemicalConcentration('')
    setMeasurementUnit(dropdownOptions[0].name)
    setChemicalModal(true);
  }

  const handleDeleteClick = (id) => {
    setDeleteId(id)
    setDeleteModal(true)
  }

  const deleteSelected = () => {
    deleteChemical(deleteId); 
    setDeleteId(null); 
    setDeleteModal(false)
  }

  return (
    <DashboardLayout>
      {isLoading && <p>...Loading</p>}
      {isError && <ErrorAlert errorMessages={error.message} />}

      <div className='flex justify-end mr-4 mt-2'>
        <PrimaryButton
          label='Create new chemical'
          onClickEvent={handleCreateClick}
          displayPlus
        />
      </div>
      <PopupBox
        modal={chemicalModal}
        handleClose={() => setChemicalModal(false)}
        title={chemicalId ? 'Update chemical' : 'Add new chemical'}
        setModal={setChemicalModal}
        name={chemicalName}
        setName={setchemicalName}
        content={chemicalContent}
        setContent={setchemicalContent}
        concentration={chemicalConcentration}
        setConcentration={setChemicalConcentration}
        dropdown={measurementUnit}
        setDropdown={setMeasurementUnit}
        measurementLabel='measurement'
        dropdownOptions={dropdownOptions}
        dropdownLabel='unit'
        errors={errors}
        handleSubmit={handleSubmit}
      />

      <DeletePopup onDelete={deleteSelected} label='Delete Chemical' desc='Are you sure you want to delete this chemical?' open={deleteModal} setOpen={setDeleteModal} />

      <div className='m-5'>
        <CardList data={data} onEditClick={handleEditClick} onDeleteClick={handleDeleteClick} renderType='chemicals' />
      </div>
    </DashboardLayout>
  );

}