
import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { rotateToken } from '../hooks/useAuth'

export const axiosProtected = axios.create()

axiosProtected.interceptors.request.use(async (config) => {
    let currentDate = new Date()
    const decodedToken = jwtDecode(localStorage.token)
    if (decodedToken.exp * 1000 > currentDate.getTime()) {
        const data = await rotateToken()
        config.headers['Authorization'] = `Bearer ${data.token}`
        localStorage.setItem('token', data.token)
    }
    return config
}, (error) => {
    return Promise.reject(error)
})