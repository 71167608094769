import { useState } from "react";
import AddressesTimeline from "./AddressesTimeline";
import JobsTimeline from "./JobsTimeline";
import Timeline from "./Timeline";

export default function MarkerDetails({ step, setStep, job, propertyId, setPropertyId }) {
    const [jobId, setJobId] = useState()

    if (step === 1) {
        return (
            <div className="bg-white rounded-lg sm:w-1/4 w-full min-w-[250px] mt-6 sm:mt-0 overflow-auto border">
                {job.length > 0 ?
                    <AddressesTimeline jobs={job} setStep={setStep} setPropertyId={setPropertyId} />
                    : <p className="text-center mt-2">Nothing to show here</p>
                }
            </div>
        )
    } else if (step === 2) {
        return (
            <div className="bg-white rounded-lg sm:w-1/4 w-full overflow-auto min-w-[250px] mt-6 sm:mt-0 border">
                <JobsTimeline propertyId={propertyId} setStep={setStep} setJobId={setJobId} />
            </div>
        )
    } else if (step === 3) {
        return (
            <div className="bg-white rounded-lg sm:w-1/4 w-full overflow-auto border min-w-[250px] mt-6 sm:mt-0">
                <Timeline setStep={setStep} jobId={jobId} />
            </div>
        )
    }
    return (
        <div className="bg-white rounded-lg sm:w-1/4 w-full overflow-auto shadow-md min-w-[250px] mt-6 sm:mt-0">
            <p className="text-center">Something went wrong.</p>
        </div>
    )
}
