import React, { useState } from 'react'
import { useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import DashboardLayout from '../components/DashboardLayout'
import SearchTable from '../components/Search/SearchTable'
import { useSearchJobs } from '../hooks/useJobs'
import { PAGE_LIMIT } from '../utils/contants'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { getUser, useUser } from '../hooks/useAuth'
export default function Search() {
    
    const [searchQuery, setSearchQuery] = useState('')
    const [input, setInput] = useState('')
    const [pageNumber, setPageNumber] = useState(0)
    const { data: userData } = useUser()
    const { data, isLoading, isError, error, refetch } = useSearchJobs(
        {
            page: pageNumber,
            search: searchQuery,
            user: userData?.user?.role === "user" ? userData?.user?._id : userData?.user?.ownerUserId, technician: userData?.user?.role === "technician" ? userData?.user?._id : null, 
            completeUser: userData?.user,
            role: userData?.user?.role
        }
    )
    const pageCount = () => {
        let count = data?.jobCount / PAGE_LIMIT
        if (count < 1) return 0
        return Math.ceil(count)
    }

    useEffect(() => {
        refetch()
    }, [pageNumber, searchQuery, userData])

    const handleClickSearch = () => {
        setSearchQuery(input)
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleClickSearch()
        }
    }

    const handlePageClick = (event) => {
        setPageNumber(event.selected)
    }

    return (
        <DashboardLayout>
            <section className='mt-10 px-10'>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-3xl font-medium text-gray-900">Search</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                A list of all the jobs in your account including their address, status, chemicals and technician.
                            </p>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 border flex border-gray-300 px-2 py-1 rounded-xl focus-within:ring-1 focus-within:ring-blue-500 focus-within:border-blue-500">
                            <MagnifyingGlassIcon className='w-6 text-gray-400' />
                            <input type="text" className='mr-2 py-1 px-2 focus-visible:outline-none' placeholder='search' 
                                value={input}
                                onChange={(e) => {setInput(e.target.value)}}
                                onKeyDown={handleKeyPress}
                            />
                        </div>
                    </div>
                </div>
                <div className="px-4 sm:px-6 lg:px-8">
                    <SearchTable isLoading={isLoading} isError={isError} error={error} data={data} />
                    <div className='mt-3 float-right'>
                        <ReactPaginate
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={2}
                            pageCount={pageCount()}
                            previousLabel="<"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    </div>
                </div>
            </section>
        </DashboardLayout>
    )
}
