import { PlusIcon } from '@heroicons/react/24/outline'
import React from 'react'

export function PrimaryButton({ label, onClickEvent, displayPlus }) {
    return (
        <button className='bg-blue-500 px-3 py-2 rounded-xl text-white text-sm hover:bg-blue-600 transition-all flex items-center gap-x-1.5'
            onClick={onClickEvent}>
            {displayPlus && <PlusIcon className='w-4' />}
            <span className='mb-0.5'>
                {label}
            </span>
        </button>
    )
}
