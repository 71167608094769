import { useMutation, useQueryClient } from "@tanstack/react-query"
import { axiosProtected } from "../utils/axios-interceptor"


const publishUpdate = async (update) => {
  const { data } = await axiosProtected.post(`${process.env.REACT_APP_IP}/update/newUpdate`, update)
  return data
}

export const usePublishUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation(publishUpdate, {
    onSuccess: () => {
      queryClient.invalidateQueries('updates');
    },
  });
};
