import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import React, { useCallback, useEffect, useState } from 'react';
import { ModalBox } from '../components/adminMap/ModalBox';
import DashboardLayout from '../components/DashboardLayout';
import { JsonEditor } from '../components/jsonEditor/JsonEditor';
import { Loader } from '../components/Loader/Loader';
import DropdownMenu from '../components/Menus/DropdownMenu';
import { ErrorAlert } from '../components/Popups/ErrorAlert';
import { SearchableDropdown } from '../components/SearchableDropdown/SearchableDropdown';
import { useGetData } from '../hooks/useData';
import { useFetchHardwares } from '../hooks/useHardware';

const FILTER_LIST = [
  { name: 'Today' },
  { name: 'Yesterday' },
  { name: 'Last 7 Days' },
  { name: 'Last 30 Days' },
  { name: 'All Time' }
];

const center = {
  lat: 42.06012414889974,
  lng: -91.63162586405998
};

const containerStyle = {
  height: '600px',
  borderRadius: '10px',
  boxShadow: '0px 0px 20px #8888'
};

function AdminMapView() {
  const [mapWidth, setMapWidth] = useState(() => (window.innerWidth < 769 ? `${window.innerWidth - 40}px` : '800px'));

  useEffect(() => {
    const handleResize = () => {
      setMapWidth(window.innerWidth < 769 ? `${window.innerWidth - 40}px` : '800px');
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
  });

  const [selectedFilter, setSelectedFilter] = useState('Last 30 Days');
  const [selectedHardwareId, setSelectedHardwareId] = useState(null)
  const [selectedOption, setSelectedOption] = useState('');
  const [step, setStep] = useState(1);
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(11);
  const [propertyId, setPropertyId] = useState(null);
  const [detailsModal, setDetailsModal] = useState(false)
  const { data: hardwareData } = useFetchHardwares()
  const { data, isError, error, isLoading } = useGetData({
    selectedFilter: selectedFilter,
    hardwareId: selectedHardwareId
  })
  const onLoad = useCallback(map => setMap(map), []);
  const onUnmount = useCallback(() => setMap(null), []);

  useEffect(() => {
    if (selectedOption) {
      const hId = hardwareData.find((hardware) => hardware.name === selectedOption)?.hwid;
      setSelectedHardwareId(hId);
    }
  }, [selectedOption])

  useEffect(() => {
    if (!propertyId) {
      return;
    }

    const selected = data?.find(d => d?._id === propertyId);
    if (selected) {
      setZoom(20);
      map?.panTo(selected.mapCenter);
    }

    if (step === 1) {
      setPropertyId(null)
      setZoom(11);
    }
  }, [map, selectedFilter, propertyId, selectedOption, data, step, detailsModal]);

  const onMarkerClick = id => {
    setStep(2);
    setPropertyId(id);
    setDetailsModal(true)
  };


  const RenderModal = () => {
    if (!propertyId) return null

    return <ModalBox modal={detailsModal} setModal={setDetailsModal} handleClose={() => setDetailsModal(false)} title={'Recieved Data Details'}>
      <JsonEditor propertyId={propertyId} />
    </ModalBox>
  }

  const DisplayAddress = () => (
    data.map(data => (
      <Marker
        key={data._id}
        position={data.mapCenter}
        icon={{
          url: 'https://cdn2.iconfinder.com/data/icons/house-property-and-real-state-color/512/home_location_map_pin_address-512.png',
          scaledSize: new window.google.maps.Size(50, 50)
        }}
        onClick={() => onMarkerClick(data._id)}
      />
    ))
  );
  const dropdownOptions = hardwareData?.map((hardware) => hardware.name);

  if (isLoading || data === undefined) return <Loader />;
  if (isError) return <ErrorAlert errorMessages={error.message} />;

  return isLoaded ? (
    <DashboardLayout>
      <RenderModal />
      <div className='w-full block p-5'>
        <div className='flex'>
          <span className='w-[330px]'></span>
          <div className='flex mx-3 py-1 h-full'>
            <SearchableDropdown
              options={dropdownOptions}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              placeholder={'Search by Hardware'}
            />
          </div>
          <span className='w-[200px] mb-5'>
            <DropdownMenu
              label=''
              dropdownOptions={FILTER_LIST}
              selector={selectedFilter}
              setSelector={setSelectedFilter}
              arrowDirectionUp={false}
              setStep={setStep}
            />
          </span>
        </div>
        <div className='sm:flex gap-x-4'>
          <GoogleMap
            mapContainerStyle={{ ...containerStyle, width: mapWidth }}
            center={center}
            onLoad={onLoad}
            onUnmount={onUnmount}
            zoom={zoom}
          >
            <DisplayAddress />
          </GoogleMap>
        </div>
      </div>
    </DashboardLayout>
  ) : null;
}

export default React.memo(AdminMapView);
