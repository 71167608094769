import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    step: 1,
    chemicals: [],
    hardwares: [],
    address: '',
    mapCenter: {
        lat: 0,
        lng: 0
    }
}

export const jobDetailsSlice = createSlice({
    name: 'jobDetails',
    initialState,
    reducers: {
        setMapCenter: (state, action) => {
            state.mapCenter = {
                lat: action.payload.lat,
                lng: action.payload.lng
            }
        },
        setStep: (state, action) => {
            state.step = action.payload
        },
        setAddress: (state, action) => {
            state.address = action.payload
        },
        setChemical: (state, action) => {
            if (state.chemicals.includes(action.payload)) {
                const filteredChemicals = state.chemicals.filter((item) => item !== action.payload)
                state.chemicals = filteredChemicals
            } else {
                state.chemicals.push(action.payload)
            }
        },
        setHardware: (state, action) => {
            if (state.hardwares.includes(action.payload)) {
                const filteredHardwares = state.hardwares.filter((item) => item !== action.payload)
                state.hardwares = filteredHardwares
            } else {
                state.hardwares.push(action.payload)
            }
        },
        setDefaultHardware: (state, action) => {
            state.hardwares[0] = action.payload 
        },
        flushjobDetails: (state) => {
            state.step = initialState.step
            state.chemicals = initialState.chemicals
            state.address = initialState.address
            state.mapCenter = initialState.mapCenter
        }
    }
})

export const { setMapCenter, setStep, setAddress, setChemical, setHardware, setDefaultHardware, flushjobDetails } = jobDetailsSlice.actions

export default jobDetailsSlice.reducer