import DashboardLayout from "../components/DashboardLayout";
import JobInProgress from "../components/Job/JobInProgress";
import JobCompleted from "../components/Job/JobCompleted";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { flushjobDetails } from "../features/jobDetailsSlice";
export default function Job() {
	const jobDetails = useSelector(state => state.jobDetails)
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(flushjobDetails())
	}, [])
	return (
		<DashboardLayout>
			{ jobDetails.step === 3 ? 
				<JobCompleted /> 
				: 
				<JobInProgress /> }
		</DashboardLayout>
	)
}
