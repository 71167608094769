import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Input } from '../Input/Input';
import CountryFilter from '../CountryFilter/CountryFilter';
import { UserIcon } from '@heroicons/react/24/outline';

const EditProfile = () => {

    const [canUpdate, setCanUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [companyPhone, setCompanyPhone] = useState();
    const [companyAddress, setCompanyAddress] = useState();
    const [companyWebsite, setCompanyWebsite] = useState();
    const [bussinessEmail, setBussinessEmail] = useState();
    const [country, setCountry] = useState()

    const onDrop = useCallback((acceptedFiles) => {
        console.log("Image Uploaded!");
        // imageUpload(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        disabled: !canUpdate,
    });

    const setCancel = () => {
        setCanUpdate(false);
        // setImageUrl(customer?.customerLogo);
        // setCompanyPhone(customer.customerPhone ? customer.customerPhone : '');
        // setCompanyAddress(customer.customerAddress ? customer.customerAddress : '');
        // setCompanyWebsite(customer.customerWebsite ? customer.customerWebsite : '');
        // const email = getBusinessEmail(customer.businessEmail)
        // setBusinessEmail(email ? arrayToString(email) : '');
    };

    const onEditCustomer = () => {
        setCanUpdate(true);
        setImageLoading(false);
        setImageUrl("");
    }

    return (
        <div>
            <div className='flex justify-between items-center'>
                <h1 className='text-base font-bold'>Your Profile</h1>
                <div className='flex justify-between items-center'>
                    {canUpdate && (
                        <button
                            className="text-[#1A1C1C] px-[34px] py-2 mr-4 rounded-lg border border-gray-300 text-sm font-medium leading-5 h-[36px]"
                            onClick={setCancel}
                        >
                            Cancel
                        </button>
                        // <UpdateButton
                        //   label="Cancel"
                        //   size="medium"
                        //   onClick={setCancel}
                        //   width='145px'
                        // />
                    )}
                    <button
                        className={`bg-[#548EFC] hover:bg-[#2563EB] text-white px-4 py-2 rounded-lg text-sm font-medium leading-5`}
                        onClick={onEditCustomer}
                    >
                        {loading ? 'Loading' : !canUpdate ? 'Update Profile' : 'Save changes'}
                    </button>
                    {/* <div className='min-w-[115px]'>
            <UpdateButton
              label={loading ? 'Loading' : !canUpdate ? 'Update Profile' : 'Save changes'}
              size="medium"
              onClick={onEditCustomer}
              width='145px'
              primary
            />
          </div> */}
                </div>
            </div>
            <hr className="my-5 bg-gray-100 text-gray-100" />
            <div className="md:flex md:justify-start md:items-start border border-gray-100 rounded-lg p-5 gap-[88px] w-full">
                <div className="mb-20 md:mb-0">
                    {imageLoading && <div>Loading...</div>}
                    {!imageLoading && (
                        <div className="w-[150px] h-[150px] bg-[#548ffc18] rounded-full border-[3.5px] border-[#548EFC]">
                        <UserIcon className='w-16 h-16 m-auto text-[#548EFC] mt-[35px]' />
                        </div>
                    )}
                    <div>
                        <div {...getRootProps()} className="cursor-pointer">
                            {canUpdate ? (
                                <header className="w-28 mx-auto border border-gray-200 flex flex-col justify-center items-center py-2 px-4 rounded-lg hover:bg-gray-50 ">
                                    <input {...getInputProps()} />
                                    <p className=" text-black font-medium text-xs hover:text-[#548EFC]">
                                        Upload Image
                                    </p>
                                </header>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="md:grid md:grid-cols-2 md:gap-5 space-y-5 md:space-y-0 w-full">
                    <div className="col-span-1">
                        <Input options={
                            {
                                label: 'Company Name',
                                name: 'companyName',
                                value: "Tobias",
                                disabled: true
                            }
                        } />
                    </div>
                    <div className="col-span-1">
                        <Input options={
                            {
                                label: 'Company Phone',
                                name: 'companyPhone',
                                type: "number",
                                value: companyPhone,
                                disabled: !canUpdate,
                                onChange: (value) => setCompanyPhone(value),
                            }
                        } />
                    </div>
                    <div className="col-span-1">
                        <Input options={
                            {
                                label: 'Company Address',
                                name: 'companyAddress',
                                type: "text",
                                value: companyAddress || "",
                                disabled: !canUpdate,
                                onChange: (e) => setCompanyAddress(e.target.value),
                            }
                        } />
                    </div>
                    <div className="col-span-1">
                        <Input options={
                            {
                                label: 'Company Website',
                                name: 'companyWebsite',
                                type: "text",
                                value: companyWebsite,
                                disabled: !canUpdate,
                                onChange: (e) => setCompanyWebsite(e.target.value),
                            }
                        } />
                    </div>
                    <div className="col-span-1">
                        <Input options={
                            {
                                label: 'Bussiness Email',
                                name: 'bussinessEmail',
                                type: "text",
                                value: bussinessEmail,
                                disabled: !canUpdate,
                                onChange: (e) => setBussinessEmail(e.target.value),
                            }
                        } />
                    </div>
                    <div className="col-span-1">
                        <CountryFilter
                            heading_label="Select Country"
                            id="tabs"
                            name="tabs"
                            disbled={!canUpdate}
                            canUpdate={canUpdate}
                            country={country}
                            defaultValue={"United States"}
                            countriesList={["United States", "Canada"]}
                            onChange={(e) => setCountry(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditProfile