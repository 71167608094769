import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { axiosProtected } from "../utils/axios-interceptor"

const fetchVersions = async () => {
  const url=`${process.env.REACT_APP_IP}/versions/getAll`
  const { data } = await axiosProtected.get(url)
  return data
}

const getVersionById = async ({ queryKey }) => {
  const versionId = queryKey[1]
  const { data } = await axiosProtected.get(`${process.env.REACT_APP_IP}/versions/get/`, { params: { versionId } })
  return data
}

const addVersion = async (version) => {
  const { data } = await axiosProtected.post(`${process.env.REACT_APP_IP}/versions/createVersion`, version,{
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return data
}

export const usePublishVersion = () => {
  const queryClient = useQueryClient();

  return useMutation(addVersion, {
    onSuccess: () => {
      queryClient.invalidateQueries('versions');
    },
  });
};

export const useGetVersionById = (query) => {
  return useQuery(['versions', query], getVersionById)
}

export const useFetchVersions = () => {
  return useQuery(['versions'], fetchVersions)
}