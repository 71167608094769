import React, { useEffect, useState } from "react";
// import { Filter } from "../Buttons/Filter";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Tabs = ({ tabs, active, handleClick, ...props }) => {
  const handleTabClick = (tab) => {
    if (!handleClick) return
    handleClick(tab.identifier)
  }

  const [filterOptions, setFilterOptions] = useState([]);

  useEffect(() => {
    const newRegionsDetails = [];
    if (tabs) {
      tabs.forEach((range) => {
        const rangeDetails = {
          name: range.name,
          onClick: () => handleTabClick(range)
        };
        newRegionsDetails.push(rangeDetails);
      });
    }
    setFilterOptions(newRegionsDetails);
  }, []);

  return (
    <div>
      <div className={`${props?.collapseOn || "sm"}:hidden `}>
        {/* {filterOptions.length !== 0 && <Filter
          active={active}
          options={filterOptions}
        />} */}

      </div>
      <div className={`hidden ${props?.collapseOn || "sm"}:block`}>
        <nav
          className={`flex space-x-4 bg-[#548ffc18] px-4 py-2 rounded-lg w-full ${props?.fullWidth ? 'lg:w-full' : 'lg:w-[80%]'}`}
          aria-label="Tabs"
        >
          {tabs &&
            tabs.map((tab) => (
              <button
                onClick={() => handleTabClick(tab)}
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.identifier === active
                    ? "bg-white shadow-md text-[#548EFC]"
                    : "text-gray-600 hover:text-gray-800",
                  "rounded-md px-3 py-2 text-sm font-medium"
                )}
                aria-current={tab ? tab.name === active : undefined}
              >
                {tab.name}
              </button>
            ))}
        </nav>
      </div>
    </div>
  );
}
