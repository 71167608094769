import React, { useEffect, useState } from 'react'
import DashboardLayout from '../components/DashboardLayout'
import { PrimaryButton } from '../components/Button/PrimaryButton'
import { PopupBox } from '../components/Popups/PopupBox'
import { useAddHardware, useDeleteHardware, useEditHardware, useFetchHardwares } from '../hooks/useHardware'
import { ErrorAlert } from '../components/Popups/ErrorAlert'
import CardList from '../components/CardList/CardList'
import { DeletePopup } from '../components/Popups/DeletePopup'
import { Loader } from '../components/Loader/Loader'

export function Hardware() {
    const { mutate: addHardware } = useAddHardware()
    const { mutate: editHardware } = useEditHardware()
    const { mutate: deleteHardware } = useDeleteHardware()
    const { data, isError, error, isLoading } = useFetchHardwares()
    const [hardwareModal, setHardwareModal] = useState(false)
    const [hardwareName, setHardwareName] = useState('')
    const [hardwareDesc, setHardwareDesc] = useState('')
    const [hardwareId, setHardwareId] = useState('')
    const [editHardwareId, setEditHardwareId] = useState(null)
    const [deleteId, setDeleteId] = useState(null)
    const [deleteModal, setDeleteModal] = useState(false)
    const [errors, setErrors] = useState('')
    const dropdownOptions = [
        { name: 'No' },
        { name: 'Yes' },
    ]
    const [dropdown, setDropdown] = useState(dropdownOptions[0].name)

    function handleSubmit() {
        if (!hardwareName) {
            setErrors(['Hardware name is not defined.'])
            return
        } else if (!hardwareDesc) {
            setErrors(['Hardware description is not defined.'])
            return
        } else if (!hardwareId) {
            setErrors(['Hardware ID is not defined.'])
            return
        } else {
            if (editHardwareId) {
                editHardware({
                    editHardwareId,
                    hardwareName,
                    hardwareDesc,
                    hardwareId,
                    dropdown
                })
            } else {
                addHardware({
                    hardwareName,
                    hardwareDesc,
                    hardwareId,
                    dropdown
                })
            }
            setHardwareModal(false)
            setHardwareDesc('')
            setHardwareId('')
            setHardwareName('')
            setDropdown(dropdownOptions[0].name)
        }
    }

    const handleEditClick = (card) => {
        setEditHardwareId(card._id)
        setHardwareName(card.name)
        setHardwareId(card.hwid)
        setHardwareDesc(card.description)
        setDropdown(card.defaultHardware ? 'Yes' : 'No')
        setHardwareModal(true)
    }

    const handleCreateClick = () => {
        setEditHardwareId(null)
        setHardwareName()
        setHardwareId()
        setHardwareDesc()
        setDropdown(dropdownOptions[0].name)
        setHardwareModal(true)
    }

    const handleDeleteClick = (id) => {
        setDeleteId(id)
        setDeleteModal(true)
    }

    const deleteSelected = () => {
        deleteHardware(deleteId); 
        setDeleteId(null);
        setDeleteModal(false)
    }

    useEffect(() => {
        setErrors([])
    }, [hardwareName, hardwareDesc, dropdown])
    return (
        <DashboardLayout>
            {isLoading && <Loader />}
            {isError && <ErrorAlert errorMessages={error.message} />}
            <div className='flex justify-end mr-4 mt-2'>
                <PrimaryButton
                    label='Add hardware'
                    onClickEvent={handleCreateClick}
                    displayPlus
                />
            </div>
            <PopupBox
                modal={hardwareModal}
                setModal={setHardwareModal}
                handleClose={() => setHardwareModal(false)}
                title={hardwareId ? 'Update hardware' : 'Add new hardware'}
                name={hardwareName}
                setName={setHardwareName}
                content={hardwareDesc}
                setContent={setHardwareDesc}
                setConcentration={setHardwareId}
                concentration={hardwareId}
                measurementLabel='hardware ID'
                dropdownOptions={dropdownOptions}
                dropdownLabel='Make default?'
                dropdown={dropdown}
                setDropdown={setDropdown}
                errors={errors}
                handleSubmit={handleSubmit}
            />

            <DeletePopup onDelete={deleteSelected} label='Delete Hardware' desc='Are you sure you want to delete this hardware?' open={deleteModal} setOpen={setDeleteModal} />

            <div className='m-5'>
                <CardList data={data} onEditClick={handleEditClick} onDeleteClick={handleDeleteClick} renderType='hardwares' />
            </div>
        </DashboardLayout>
    )
}
