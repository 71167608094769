import DashboardLayout from "../components/DashboardLayout"
import {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";
import {useUser} from "../hooks/useAuth";
import {getOwnerUser} from "../hooks/useAuth"
import { Loader } from "../components/Loader/Loader";

const Profile = () => {
    
    const [imageUrl, setImageUrl] = useState("https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80");
    const [imageLoading, setImageLoading] = useState(false);
    const [tempFile, settempFile] = useState(null);
    const [name, setName] = useState('');
    const [canUpdate, setCanUpdate] = useState(false);
    const {data: userData} = useUser();
    const [ownerUser, setOwnerUser] = useState(null);
    
    const onDrop = useCallback((acceptedFiles) => {
//        imageUpload(acceptedFiles[0]);
        }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        disabled: false,
    });

    useEffect(() => {
        getOwnerUserById()
    }, [userData?.user])

    const getOwnerUserById = async() => {
        if (userData?.user) {
            const {data} = await getOwnerUser(userData?.user?.ownerUserId)

            setOwnerUser(data)
        }
    }

    if (!userData?.user) return <Loader />
    
    return (
        <DashboardLayout>
            <div className="h-full flex">
                <div className="flex-1 min-w-0 flex flex-col overflow-hidden">
                    <main className="flex-1 flex overflow-hidden">
                        <div className="flex-1 flex flex-col overflow-y-auto xl:overflow-hidden">
                            <div className="flex-1 flex xl:overflow-hidden">
                                <div className="flex-1 xl:overflow-y-auto">
                                    <div className="max-w-3xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
                                        {/*<div className=''>*/}
                                        {/*    {errorMessages && errorMessages.length > 0 && (*/}
                                        {/*        <ErrorAlert errorMessages={errorMessages} />*/}
                                        {/*        )}*/}
                                        {/*</div>*/}
                                        {/*<h1 className="text-3xl font-extrabold text-blue-gray-900">Account</h1>*/}
                                        <form className="mt-6 space-y-8 divide-y divide-y-blue-gray-200">
                                            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                                                <div className="sm:col-span-6">
                                                    <h2 className="text-xl font-medium text-blue-gray-900">Profile</h2>
                                                    <p className="mb-0 mt-1 text-sm text-blue-gray-500">
                                                        This information will be displayed publicly so be careful what you share.
                                                    </p>
                                                </div>
                                                <div className="sm:col-span-6">
                                                    {/* <label htmlFor="photo" className="block text-sm font-medium text-blue-gray-900">
                                                        Photo
                                                    </label> */}
                                                    {/*{imageLoading && <Loading width={80} />}*/}
                                                     {imageUrl && !imageLoading && (
                                                        <div className="mb-4 flex justify-center">
                                                            <img
                                                                className="preview rounded-full w-[150px] h-[150px]"
                                                                src={imageUrl}
                                                                alt=""
                                                                width="300px"
                                                            />
                                                        </div>
                                                        )} 
                                                    {/* <div className="cursor-pointer">
                                                        <header className="my-8 border-dashed border-1 border-green-400 bg-green-50 py-12 flex flex-col justify-center items-center">
                                                            <input {...getInputProps()} />
                                                            {isDragActive ? (
                                                                <p>Drop the Profile Image here ...</p>
                                                            ) : (
                                                                <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center">
                                                                    <span>Drag and drop your</span>
                                                                    &nbsp;
                                                                    <span>Profile Image or click to upload</span>
                                                                </p>

                                                            )}
                                                        </header>
                                                        <header {...getRootProps()} className="w-28 mx-auto border border-gray-200 flex flex-col justify-center items-center py-2 px-4 rounded-lg hover:bg-gray-50 ">
                                                            <p className=" text-black font-medium text-xs hover:text-green-500">
                                                                Upload Image
                                                                <input {...getInputProps()} />
                                                            </p>
                                                        </header>
                                                    </div> */}
                                                    {canUpdate && 
                                                    <header {...getRootProps()} className="w-28 mx-auto border border-gray-200 flex flex-col justify-center items-center py-2 px-4 rounded-lg hover:bg-gray-50 ">
                                                        <p className="mb-0  text-black font-medium text-xs hover:text-green-500">
                                                            Upload Image
                                                            <input {...getInputProps()} />
                                                        </p>
                                                    </header>
                                                    }
                                                </div>
                                                <div className="sm:col-span-6">
                                                    <label htmlFor="name" className="block text-sm font-medium text-blue-gray-900">
                                                        Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        value={userData?.user?.username}
                                                        disabled={!canUpdate}
                                                        className="mt-1 block w-full p-2 border border-blue-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-green-400 focus:border-green-400"
                                                        // defaultValue={name}
//                                                        onChange={(event) => {
//                                                        setName(event.target.value)
//                                                    }}
                                                    />
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label htmlFor="email-address" className="block text-sm font-medium text-blue-gray-900">
                                                        Email address
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="email-address"
                                                        id="email-address"
                                                        autoComplete="email"
                                                        value={userData?.user?.email}
                                                        disabled
                                                        className="mt-1 block w-full p-2 border border-blue-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-green-400 focus:border-green-400"
                                                    />
                                                </div>
                                                <div className="sm:col-span-3">
                                                    <label htmlFor="company" className="block text-sm font-medium text-blue-gray-900">
                                                        Company
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="company"
                                                        id="company"
                                                        autoComplete="email"
                                                        value={ownerUser?.username}
                                                        disabled
                                                        className="mt-1 block w-full p-2 border border-blue-300 rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-green-400 focus:border-green-400"
                                                    />
                                                </div>
                                            </div>

                                            {/*<div className="pt-8 flex justify-end">*/}
                                            {/*    {canUpdate && */}
                                            {/*<button*/}
                                            {/*    className="text-[#1A1C1C] px-[34px] py-2 mr-4 rounded-lg border border-gray-300 text-sm font-medium leading-5 h-[36px]"*/}
                                            {/*    onClick={() => setCanUpdate(false)>*/}
                                            {/*    Cancel*/}
                                            {/*</button>*/}
                                            {/*}*/}
                                            {/*    <button*/}
                                            {/*        onClick={(event) => {*/}
                                            {/*        event.preventDefault()*/}
                                            {/*            formSubmit()*/}
                                            {/*    }}*/}
                                            {/*        disabled={loading}*/}
                                            {/*        type="submit"*/}
                                            {/*        className='bg-[#28A662] text-white px-4 py-2 rounded-lg text-sm font-medium leading-5'*/}
                                            {/*        >*/}
                                            {/*        {!loading ? !canUpdate ? "Update Profile" : "Save Changes" : "Loading"}*/}
                                            {/*    </button>*/}
                                            {/*</div>*/}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Profile;