import React, { useEffect, useCallback, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import DashboardLayout from '../components/DashboardLayout';
import { useGetAllUniqueJobs } from '../hooks/useJobs';
import MarkerDetails from '../components/MapView/MarkerDetails';
import DropdownMenu from '../components/Menus/DropdownMenu';
import { useUser } from '../hooks/useAuth';
import { ErrorAlert } from '../components/Popups/ErrorAlert';
import { Loader } from '../components/Loader/Loader';

const FILTER_LIST = [
  { name: 'Today' },
  { name: 'Yesterday' },
  { name: 'Last 7 Days' },
  { name: 'Last 30 Days' }
];

const center = {
  lat: 42.06012414889974,
  lng: -91.63162586405998
};

const containerStyle = {
  height: '600px',
  borderRadius: '10px',
  boxShadow: '0px 0px 20px #8888'
};

function MapView() {
  // State for dynamic map width
  const [mapWidth, setMapWidth] = useState(() => (window.innerWidth < 769 ? `${window.innerWidth - 40}px` : '800px'));

  // Update map width on window resize
  useEffect(() => {
    const handleResize = () => {
      setMapWidth(window.innerWidth < 769 ? `${window.innerWidth - 40}px` : '800px');
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Load Google Maps API
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
  });

  const [step, setStep] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState('Today');
  const { data: userData } = useUser();
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(4);
  const [propertyId, setPropertyId] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);

  // Set map instance when loaded
  const onLoad = useCallback(map => setMap(map), []);
  const onUnmount = useCallback(() => setMap(null), []);

  // Fetch unique jobs
  const { data, isError, error, isLoading } = useGetAllUniqueJobs({
    user: userData?.user?._id,
    technician: userData?.user?.role === 'technician' ? userData?.user?._id : null,
    selectedFilter: selectedFilter
  });

  // Update selected address and zoom level when propertyId changes
  useEffect(() => {
    if (!propertyId) {
      setSelectedAddress(null);
      return;
    }
    const selected = data?.find(address => address?._id === propertyId);
    if (selected) {
      setSelectedAddress(selected);
      setZoom(20);
      map?.panTo(selected.mapCenter); // Center map on selected address
    }

    if (step === 1) {
      setZoom(4);
      setSelectedAddress(undefined);
    }

  }, [propertyId, data, map, step]);

  const onMarkerClick = jobAddressId => {
    setStep(2);
    setPropertyId(jobAddressId);
  };

  const DisplayAddress = () => {

    if (!selectedAddress) return data.map(jobAddress => (
      <Marker
        key={jobAddress._id}
        position={jobAddress.mapCenter}
        icon={{
          url: 'https://cdn2.iconfinder.com/data/icons/house-property-and-real-state-color/512/home_location_map_pin_address-512.png',
          scaledSize: new window.google.maps.Size(50, 50)
        }}
        onClick={() => onMarkerClick(jobAddress._id)}
      />
    ))

    return <Marker
      key={selectedAddress._id}
      position={selectedAddress.mapCenter}
      icon={{
        url: 'https://cdn2.iconfinder.com/data/icons/house-property-and-real-state-color/512/home_location_map_pin_address-512.png',
        scaledSize: new window.google.maps.Size(50, 50)
      }}
      onClick={() => onMarkerClick(selectedAddress._id)}
    />
  }


  if (isLoading || data === undefined) return <Loader />;
  if (isError) return <ErrorAlert errorMessages={error.message} />;

  return isLoaded ? (
    <DashboardLayout>
      <div className='w-full block p-5'>
        <div className='flex'>
          <span className='w-[1020px]'></span>
          <span className='w-[200px] mb-5'>
            <DropdownMenu
              label=''
              dropdownOptions={FILTER_LIST}
              selector={selectedFilter}
              setSelector={setSelectedFilter}
              arrowDirectionUp={false}
              setStep={setStep}
            />
          </span>
        </div>
        <div className='sm:flex gap-x-4'>
          <GoogleMap
            mapContainerStyle={{ ...containerStyle, width: mapWidth }} // Set map width dynamically
            center={center}
            onLoad={onLoad}
            onUnmount={onUnmount}
            zoom={zoom}
          >
            <DisplayAddress />
          </GoogleMap>
          <MarkerDetails step={step} setStep={setStep} job={data} propertyId={propertyId} setPropertyId={setPropertyId} />
        </div>
      </div>
    </DashboardLayout>
  ) : null;
}

export default React.memo(MapView);
