import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { axiosProtected } from '../utils/axios-interceptor'

const fetchChemicals = async () => {
    const { data } = await axiosProtected.get(`${process.env.REACT_APP_IP}/chemicals/chemicals`)
    return data
}

const addChemical = async (chemical) => {
    const { data } = await axiosProtected.post(`${process.env.REACT_APP_IP}/chemicals/chemical`, chemical)
    return data
}

const updateChemical = async (chemical) => {
    const { data } = await axiosProtected.patch(`${process.env.REACT_APP_IP}/chemicals/updateChemical`, chemical)
    return data
}

const deleteChemical = async (chemicalId) => {
    const { data } = await axiosProtected.delete(`${process.env.REACT_APP_IP}/chemicals/deleteChemical`, { data: { chemicalId } })
    return data
}

export const useFetchChemicals = () => {
    return useQuery(['chemicals'],fetchChemicals, {
        select: (data) => {
            return data?.data?.response?.map(chemical => chemical)
        }
    })
}

export const useCreateChemical = () => {
    const queryClient = useQueryClient()
    return useMutation(addChemical, { 
        onSuccess: () => {
            queryClient.invalidateQueries('chemicals')
        }
    })
}

export const useUpdateChemical = () => {
    const queryClient = useQueryClient()
    return useMutation(updateChemical, { 
        onSuccess: () => {
            queryClient.invalidateQueries('chemicals')
        }
    })
}

export const useDeleteChemical = () => {
    const queryClient = useQueryClient()
    return useMutation(deleteChemical, { 
        onSuccess: () => {
            queryClient.invalidateQueries('chemicals')
        }
    })
}