import { useLoadScript } from '@react-google-maps/api'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ChooseList } from '../components/ChooseList/ChooseList'
import DashboardLayout from '../components/DashboardLayout'
import GoogleAutocompleteInput from '../components/Start/GoogleAutocompleteInput'
import { setDefaultHardware } from '../features/jobDetailsSlice'
import { setJobInProgress } from '../features/jobInProgressSlice'
import { useUser } from '../hooks/useAuth'
import { useFetchChemicals } from "../hooks/useChemicals"
import { useFetchHardwares } from '../hooks/useHardware'
import { useCreateJob, useSearchJobs } from '../hooks/useJobs'
import { useCreateProperty } from '../hooks/useProperty'

// keeping libraries array as a const outside component due to performace warning in console
const libraries = ['places']

export default function Start() {
    const jobDetails = useSelector(state => state.jobDetails)

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries,
    })
    const dispatch = useDispatch()
    const nav = useNavigate()
    const { mutateAsync: createJob } = useCreateJob()
    const { mutateAsync: createProperty } = useCreateProperty()
    const { data: userData } = useUser()
    const { data: chemicals, isError: isChemicalsError, isLoading: isChemicalsLoading, error: chemicalError } = useFetchChemicals()
    const { data: hardwares, isError: isHardwareError, isLoading: isHardwareLoading, error: hardwareError } = useFetchHardwares()
    const date = new Date()
    const { data, refetch: refetchCurrentJobs } = useSearchJobs(
        {
            page: 1,
            search: '',
            user: userData?.user?.role === "user" ? userData?.user?._id : userData?.user?.ownerUserId, technician: userData?.user?.role === "technician" ? userData?.user?._id : null,
            completeUser: userData?.user,
            role: userData?.user?.role,
            active: true
        }
    )

    useEffect(() => {
        if (userData) {
            dispatch(setDefaultHardware(userData.user.defaultHardware))
        }
    }, [])

    useEffect(() => {
        refetchCurrentJobs()
        if (!data?.jobs?.length) return;
        const { jobs } = data
        const currentOpenJob = jobs[0]
        dispatch(setJobInProgress(currentOpenJob))
        nav('/job')
    }, [userData, data])


    const onClickStartJob = async () => {
        const property = await createProperty({ address: jobDetails.address, mapCenter: jobDetails.mapCenter })
        const res = await createJob({
            address: property._id,
            chemicals: jobDetails.chemicals,
            hardwares: jobDetails.hardwares,
            ownerUserId: userData?.user.role === "user" ? userData?.user?._id : userData?.user?.ownerUserId,
            startTime: date,
            endTime: null,
            technician: userData?.user.role === "technician" ? userData?.user?._id : null
        })
        dispatch(setJobInProgress(res))
        nav('/job')
    }

    return (
        <DashboardLayout>
            <div className='mt-10'>
                {isLoaded && <GoogleAutocompleteInput />}
            </div>

            {jobDetails.step === 2 &&
                <>
                    <div className='m-5'>
                        <ChooseList title='Chemicals' data={chemicals} isError={isChemicalsError} isLoading={isChemicalsLoading} error={chemicalError} />
                    </div>
                    <div className='m-5'>
                        <ChooseList title='Hardwares' data={hardwares} isError={isHardwareError} isLoading={isHardwareLoading} error={hardwareError} />
                    </div>
                    {jobDetails.chemicals.length > 0 && jobDetails.hardwares.length > 0 &&
                        <div className="w-full text-center ">
                            <button
                                className="bg-fuchsia-700 text-white px-5 py-3 text-xl text-center rounded-md w-60 hover:bg-fuchsia-900 transition-all tracking-wider"
                                onClick={onClickStartJob}>
                                START JOB
                            </button>
                        </div>
                    }
                </>
            }
        </DashboardLayout>
    )
}
