import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime' 

export const formatDate = (date) => {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.extend(relativeTime)
    const user_timeZone = dayjs.tz.guess()
    return `${dayjs(date).tz(user_timeZone).format('MMMM DD, YYYY [at] hh:mm A')}`
  }