import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

export const TimeDuration = (filter) => {
    dayjs.extend(utc)
    let startDate;
    let endDate = dayjs.utc().local().format();
    if (filter === "Today") {
        startDate = dayjs.utc().local().startOf('day').format();
    }
    else if (filter === "Yesterday") {
        startDate = dayjs.utc().local().subtract(1, 'days').startOf('day').toISOString();
        endDate = dayjs.utc().local().subtract(1, 'days').endOf('day').toISOString();
    }
    else if (filter === "Last 7 Days")
        startDate = dayjs.utc().local().subtract(7, 'days').startOf('day').toISOString();
    else if (filter === "Last 30 Days")
        startDate = dayjs.utc().local().subtract(1, 'months').startOf('day').toISOString();
    else if (filter === "All Time") 
    {    startDate = null;
        endDate=null;
    }
    else startDate = dayjs("2020-01-01T10:00:00").toISOString();

    return { startDate, endDate }
}