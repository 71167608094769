import React from 'react'

export function Input({options}) {
    return (
        <div className="w-full">
            <div className="block">
                <label className="flex justify-start items-center text-[14px] mb-2">
                    <span>{options.label}</span>
                </label>
            </div>
            <input 
                name={options.name} 
                id={options.id} 
                type={options.type}
                disabled={options.disabled}
                className="border border-gray-300 focus-visible:outline-none focus:border-blue-600 hover:border-blue-400 w-full p-2 rounded-lg" 
                value={options.value} 
                onChange={options.onChange} />
        </div>
    )
}
