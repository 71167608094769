import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';

export default function DropdownMenu({
    dropdownOptions,
    label,
    selector,
    setSelector,
    arrowDirectionUp,
    setStep = 1,
    type = 'default',
    placeholder = 'Choose an Option'
}) {
    const allSelected = selector.length === dropdownOptions.length;

    function handleClick(valName, e) {
        e.preventDefault()
        e.stopPropagation()
        if (type === 'checkbox') {
            if (valName === 'Select All') {
                if (allSelected) {
                    setSelector([]);
                } else {
                    setSelector(dropdownOptions.map((option) => option.name));
                }
            } else {
                const isSelected = selector.includes(valName);
                if (isSelected) {
                    setSelector(selector.filter((option) => option !== valName));
                } else {
                    setSelector([...selector, valName]);
                }
            }
        } else {
            setSelector(valName);
        }
    }

    function isSelected(valName) {
        return selector.includes(valName);
    }

    return (
        <div className="w-full">
            <Menu as="div" className="relative w-full inline-block text-left">
                <div>
                    <span className="flex justify-start items-center text-[14px] mb-2">
                        {label}
                    </span>
                    <Menu.Button className="inline-flex w-full justify-between items-center rounded-md bg-white px-4 py-2.5 text-sm text-gray-900 hover:bg-blue-50 border border-gray-300">
                        <div className="text-[14px] flex-grow-1">
                            <span>
                                {type === 'checkbox'
                                    ? selector.length > 0
                                        ? 'Hardwares Selected'
                                        : placeholder
                                    : selector.length ? selector : placeholder}
                            </span>
                        </div>
                        {arrowDirectionUp ? (
                            <ChevronUpIcon className="ml-2 -mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                        ) : (
                            <ChevronDownIcon className="ml-2 -mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                        )}
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        static
                        className={`absolute z-50 w-full divide-y 
                        divide-gray-100 rounded-md bg-white shadow-lg ring-1 
                        ring-black ring-opacity-5 focus:outline-none max-h-72 overflow-y-auto
                        ${arrowDirectionUp ? 'bottom-12' : 'mt-2'}
                        `}
                    >
                        <div className="px-1 py-1">
                            {type === 'checkbox' && (
                                <Menu.Item>
                                    {({ active }) => (
                                        <button
                                            className={`${active
                                                ? 'bg-blue-50 text-blue-500 hover:stroke-blue-500 hover:fill-blue-500'
                                                : 'text-gray-900 stroke-gray-400'
                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            onClick={(e) => handleClick('Select All', e)}
                                        >
                                            <input
                                                type="checkbox"
                                                className="mr-2"
                                                checked={allSelected}
                                                readOnly
                                            />
                                            Select All
                                        </button>
                                    )}
                                </Menu.Item>
                            )}
                            {dropdownOptions.map((val) => (
                                <Menu.Item key={val.name}>
                                    {({ active }) => (
                                        <button
                                            className={`${active
                                                ? 'bg-blue-50 text-blue-500 hover:stroke-blue-500 hover:fill-blue-500'
                                                : 'text-gray-900 stroke-gray-400'
                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                            onClick={(e) => handleClick(val.name, e)}
                                        >
                                            {type === 'checkbox' && (
                                                <input
                                                    type="checkbox"
                                                    className="mr-2"
                                                    checked={isSelected(val.name)}
                                                    readOnly
                                                />
                                            )}
                                            {val.name}
                                        </button>
                                    )}
                                </Menu.Item>
                            ))}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
}
