import { CloudArrowUpIcon } from '@heroicons/react/24/outline';
import React from 'react';


const FileUpload = ({ setFileSelect, files }) => {
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    setFileSelect((prevFiles) => [...prevFiles, ...droppedFiles]);
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFileSelect((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  return (
    <div className="flex flex-col items-center justify-center p-6 bg-gray-50  rounded-lg border-2 border-dashed border-blue-400">
      <div
        className="w-full max-w-lg p-6 text-center rounded-lg cursor-pointer"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <CloudArrowUpIcon className="mx-auto h-16 w-16 text-blue-500" />
        <p className="mt-4 text-sm text-gray-500">Drag and Drop Files Here</p>
        <p className="text-sm text-gray-500">or</p>
        <label className="cursor-pointer text-blue-600 hover:underline">
          <span className="text-sm">Click to Browse </span>
          <input
            type="file"
            className="hidden"
            onChange={handleFileChange}
            multiple
          />
        </label>
      </div>

      {files.length > 0 && (
        <ul className="mt-6 w-full max-w-lg">
          {files.map((file, index) => (
            <li
              key={index}
              className="flex items-center justify-between p-2 border-b border-gray-200"
            >
              <span className="text-sm text-gray-700">{file.name}</span>
              <span className="text-xs text-gray-500">
                {(file.size / 1024).toFixed(2)} KB
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default FileUpload;
