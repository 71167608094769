import {
    CheckIcon
} from '@heroicons/react/20/solid'

export default function AddressesTimeline({ jobs, setStep, setPropertyId }) {

    const handleAddressClick = (job) => {
        setStep(2)
        setPropertyId(job._id)
    }
    return (
        <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3 h-[600px]">
            <div className="px-4 py-3 sm:rounded-lg sm:px-6">
                <div className="mt-6 flow-root">
                    <ul role="list" className="-mb-8">
                        {jobs && jobs.length > 0 && jobs.map((job, jobIdx) => (
                            <li key={job._id}>
                                <div className="relative pb-8">
                                    {jobIdx !== jobs.length - 1 ? (
                                        <span
                                            className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                            aria-hidden="true"
                                        />
                                    ) : null}
                                    <div className="relative flex space-x-3">
                                        <div>
                                            <span
                                                className=
                                                'mt-2 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white bg-blue-500'
                                            >
                                                <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                            </span>
                                        </div>
                                        <div className="flex min-w-0 flex-1 justify-between items-center space-x-4 pt-1.5">
                                            <div>
                                                <p className="text-sm text-gray-500">
                                                    <button onClick={() => handleAddressClick(job)} className="font-medium text-gray-900 hover:text-gray-500">
                                                        {job.address}
                                                    </button>
                                                </p>
                                            </div>
                                            <div className="whitespace-nowrap text-right text-sm text-gray-500">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    )
}