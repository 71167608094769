import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const OnlyAdmin = ({ role, children }) => {
	const [admin, setAdmin] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [loading, setLoading] = useState(true); 
	const navigate = useNavigate();

	useEffect(() => {
		checkUserRole();
	}, []);

	const checkUserRole = () => {
		const isAdmin = role === 'admin';
		setAdmin(isAdmin);

		if (role) {
			setIsLoggedIn(true);
		} else {
			setIsLoggedIn(false);
			navigate('/signin');
		}

		setLoading(false);
	};

	if (loading) {
		return <div className='flex items-center justify-center h-screen'>
			<h1 className='mx-auto text-3xl font-bold'>Loading...</h1>
		</div>;
	}

	return isLoggedIn && admin ? (
		children
	) : (
		<div className='flex items-center justify-center h-screen'>
			<h1 className='mx-auto text-3xl font-bold underline text-red-600'>Access Denied</h1>
		</div>
	);
};

export default OnlyAdmin;
