import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { Input } from '../Input/Input';
import ReactQuillComponent from '../ReactQuillComponent';
import DropdownMenu from '../Menus/DropdownMenu';

export function PopupBox({ modal, handleClose, title, setModal, name, setName, content, setContent, concentration, setConcentration, dropdownOptions, dropdown, setDropdown, errors, handleSubmit, measurementLabel, dropdownLabel }) {

    return (
        <Transition.Root show={modal} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                open={modal}
                onClose={handleClose}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm md:max-w-md lg:max-w-lg sm:w-full sm:p-6">
                            <div className='flex items-center justify-between'>
                                <Dialog.Title as="h3" className="text-xl leading-6 font-bold text-gray-900">
                                    {title}
                                </Dialog.Title>

                                <XMarkIcon className="w-6 h-6 cursor-pointer" aria-hidden="true" onClick={() => setModal(false)} />

                            </div>
                            <div className='mt-5'>
                                <Input
                                    options={{
                                        label: 'name',
                                        name: "name",
                                        id: "name",
                                        value: name,
                                        onChange: e => setName(e.target.value),
                                        type: 'text'
                                    }}
                                />
                            </div>
                            <div className='mt-5'>
                                <p className='text-[14px]'>description</p>
                                <ReactQuillComponent content={content} setContent={setContent} />
                            </div>
                            <div className='mt-5 flex gap-x-3'>
                                <Input options={{
                                    label: measurementLabel,
                                    name: "concentration",
                                    id: "concentration",
                                    value: concentration,
                                    onChange: e => setConcentration(e.target.value),
                                    type: 'text'
                                }} />
                                <DropdownMenu
                                    label={dropdownLabel}
                                    dropdownOptions={dropdownOptions}
                                    selector={dropdown}
                                    setSelector={setDropdown}
                                    arrowDirectionUp={true}
                                />
                            </div>
                            {errors.length > 0 ? (
                                <div className='flex-col'>
                                    {errors.map((each, idx) => {
                                        return (<p key={idx} className='text-center text-red-500 text-sm mt-3 bg-red-50 p-2 font-bold'>{each}</p>)
                                    })}
                                </div>
                            ) : null}
                            <div className="mt-5 sm:mt-6">
                                <button
                                    type="button"
                                    disabled={false}
                                    className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-500 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
